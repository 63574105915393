
import React from "react";
import { Form, Breadcrumb, Table, Button, Col,Row,Spin,Switch, message,Modal,Popconfirm,Input} from 'antd';
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import App from '../../App'
import getServiceTypesBySiteId from '../../queries/getServiceTypesBySiteId'
import updateServiceType from '../../mutation/updateServiceType'
import deleteSiteServices from '../../mutation/deleteSiteServices'
import SubServiceType from './subServiceType'
import AddSubServiceType from './createSubServiceType'
import AddCustomLables from "./addCustomLables"
import UserOperation  from '../../utils/userOperations'
import SiteActionEvent from '../../funComponent/SiteActionEvent'

const FormItem = Form.Item;
const { TextArea } = Input;
const modalUiTexts = {
    "MASTER_SERVICE_UPDATE": {
        "modalTitle": "Update Menu",
    },
    "ADD_SUB_MENU": {
        "modalTitle": "Add Sub Menu",
    },
    "UPDATE_SUB_MENU": {
        "modalTitle": "update Sub Menu",
    },
}
class ServiceTypeList extends React.Component{
    constructor(props){
        super(props)
        this.state={
            loading: false,
            deleteLoading: false,
            visible: false,
            modalData: undefined,
            data: [],
            allSerType: [],
            editMenuData: {},
            eventType: "",
            showLabelsModal:false,
            notes: undefined,
        }
    }

    enterLoading=()=>{
        this.setState({
            loading: true
        })
    }
    notesChange=(e)=>{
      this.setState({
        notes: e.target.value
      })
    }
    submitMut=(value)=>{
        //  console.log("value",value)
        // this.enterLoading()
        // return
        let image = value.image;
        if(image && image.key){
          delete image.__typename
        }
        if(this.state.notes){
        this.props.client.mutate({
            mutation: updateServiceType,
            variables: {
                siteId: value.siteId,
                slugName:  value.slugName,
                typeName: value.typeName,
                typeDes: value.typeDes ? value.typeDes : undefined,
                image: image,
                typeStatus: !value.typeStatus,
                displayOrder: value.displayOrder
            }
        }).then(({ data }) => {
            console.log("data response",data.updateServiceType)
            if(data && data.updateServiceType){

              message.success('Menu Successfully Updated');
              this.createLogs(`${value.typeName} Service Status Change`)
            }
            this.setState({
                loading: false
            },()=>this.runQuery())
          })
          .catch(res => {
            console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(res, null, 2)}`)
          });
        }else{
          if(this.state.notes == undefined){
            message.error("Please Enter Notes")
          }
        }

    }
 componentDidMount(){
     this.enterLoading()
     this.runQuery()
    
 }
 createLogs=async(name)=>{
  let obj = {
      siteId: this.props.location.state.siteData.siteId,
      eventName: "Setup",
      eventType: 'MANUAL',
      // reason: this.state.selectedReason,
      note: this.state.notes
  }
  let resp = await SiteActionEvent(this.props,obj)
  if(resp){
    this.setState({
      notes: undefined
    })
  }
}
 onExpand=(record)=>{
  //  console.log("record ::",this.state.allSerType)
   let all = this.state.allSerType
   let arr = all.filter((o)=> o.masterServiceType == record.slugName)
  //  console.log("arr",arr)
   return(
    <SubServiceType data={arr} menuType={record.slugName} modalRecord={record} handleEditPressFunction={this.handleEditPressFunction}/>
   )
 }
 showModal=(record, eventType)=>{
  //  console.log("Recards ::",record)
  let editMenuData = {}
  if(eventType === "MASTER_SERVICE_UPDATE"){
    editMenuData = {
      ...record,
      eventType
     };
  }
   this.setState({
    modalData: record,
     visible: true,
     editMenuData,
     eventType
   })
 }
 handleEditPressFunction = (modalData, editMenuData, eventType) => {
  // console.log("handleEditPressFunction :::", modalData, editMenuData)
  this.setState({
    modalData: modalData,
     visible: true,
     editMenuData: {
      ...editMenuData,
      eventType
     },
     eventType
   })
  
 }
 handleCancel=()=>{
  this.runQuery()
   this.setState({
     visible: false,
     editMenuData: {},
    eventType: ""
   })
 }

 handleLableCancel=()=>{
     this.setState({
      showLabelsModal:false
     })
 }

 showLables=()=>{
  // console.log("this.props.userData",this.props.userData)
  let userPermissions = UserOperation('manageMenu','add_labels',this.props.userData)
  if(userPermissions){
   this.setState({
     showLabelsModal:true
   })
  }else{
    message.error(`Dear User, You don't have access to this page.`)
  }
 }

 runQuery = ()=>{

   this.props.client.query({
        query: getServiceTypesBySiteId,
        variables:{
          siteId: this.props.location.state.siteData.siteId
        },
        fetchPolicy: 'no-cache'
      })
        .then(({ data }) => {
          // console.log('dataa', data)
          let tempArr = []
          let arr = data.getServiceTypesBySiteId
          tempArr= arr.filter((o)=> o.masterServiceType == null)
          // console.log('tempArr ::', tempArr)
          this.setState({
              data: tempArr,
              loading: false,
              allSerType: data.getServiceTypesBySiteId
          })
        })
        .catch(err => {
          console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
        })

 }
 deleteServiceInBulk=()=>{
  let userPermissions = UserOperation('manageMenu','add_labels',this.props.userData)
if(userPermissions){
  this.setState({
    deleteLoading:true
  })
  // if(this.state.notes){
  this.props.client.mutate({
    mutation: deleteSiteServices,
    variables: {
        siteId: this.props.location.state.siteData.siteId
    }
}).then(({ data }) => {
  console.log("data ::",data)
  if(data.deleteSiteServices){
    message.success(' Successfully Service Deleted');
    this.createLogs(`Delete All Service and category`)
    this.setState({
      deleteLoading:false
    })
  }
})
.catch((err)=>{
    message.error("Graphql err")

  this.setState({
    deleteLoading:false
  })
})
// }else{
//   if(this.state.notes == undefined){
//     message.error("Please Enter Notes")
//     this.setState({
//       deleteLoading:false
//     })
//   }
// }
}else{
  message.error(`Dear User, You don't have access to this page.`)
}
 }



    render(){
      // console.log("modalData::",this.props)
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const columns = [
            {
              title: 'Menu Name',
              dataIndex: 'typeName',
              key: 'typeName',
            },
            {
              title: 'Description',
              dataIndex: 'typeDes',
              key: 'typeDes',
            },
            {
              title: 'Status',
              render:(data)=>{
                return(
                     <div style={{ marginTop: 10 }}>
                        <Popconfirm     onConfirm={(e)=>this.submitMut(data)}  title={        <TextArea    rows={2}  value={this.state.notes}    placeholder="Notes" onChange={this.notesChange}  />} okText="Save" >
                        <Switch 
                        // onChange={(e)=>this.submitMut(e,data)} 
                        checked={data.typeStatus} />
                        </Popconfirm>
                    </div>
                )
    
            }
            },
            {
              title: 'Action',
              render:(data)=>{
                return(
                  <>
                   <Button
                      icon="edit"
                      type="default"
                      style={{
                        cursor: "pointer",
                        background: "#389e0d",
                        color: "#fff",
                        marginRight: "5px"
                      }}
                      onClick={() => this.showModal(data, "MASTER_SERVICE_UPDATE")}
                    />
                  {/* <Button icon="delete" type="danger" style={{marginRight: '5px'}} /> */}
                  <Button icon="branches" type="primary" onClick={(e)=>{ this.showModal(data, "ADD_SUB_MENU")}}/>
                  </>
                )
              }
            }
          ];
        return(
            <App header={"Manage Menu"}>
                      <Breadcrumb style={{marginBottom:"20px"}}>
                    <Link to="/">
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        </Link>
                        {/* <Link to="/jaddmenu">
                        <Breadcrumb.Item>Manage Menu</Breadcrumb.Item>
                        </Link> */}
            </Breadcrumb>
            <Row>
                <Col >
                  <div style={{display:'flex', justifyContent:'space-between',float:'right'}}>
                  <div style={{marginLeft:'10px'}}>
                  <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteServiceInBulk()}>
                  <Button type="default" loading={this.state.deleteLoading}  style={{ marginBottom: 16,marginLeft: '5px', float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                       Delete All Service & Category
                        </Button>
                        </Popconfirm>
                    <Link to={{pathname :"/add-serviceType",state:{siteData:this.props.location.state.siteData}}}>
                        <Button type="default"  style={{ marginBottom: 16, float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                        Add Menu
                        </Button>
                        </Link>
                  </div>
                  <div style={{marginLeft:"10px"}}>
                  <Button type="default" onClick={this.showLables}  style={{ marginBottom: 16, float: "right", clear:"left", cursor: "pointer", background:"#389e0d", color:"#fff" }} >
                        Add Lables
                        </Button>
                  </div>
                  </div>
                  </Col>
                  </Row>
                  {this.state.loading ? <>
                            <div style={{textAlign:"center"}}>
                                <Spin size="large"/>
                            </div>
                        </>:
                        <Table
                        bordered
                        dataSource={this.state.data}
                        expandedRowRender={this.onExpand}
                        columns={columns}
                        />
                        }
                        <Modal
                          title={modalUiTexts[this.state.eventType] && modalUiTexts[this.state.eventType].modalTitle}
                          visible={this.state.visible}
                          width="60%"
                          onCancel={this.handleCancel}
                          footer={null}
                          destroyOnClose
                        >
                          <AddSubServiceType  modalData={this.state.modalData} siteId={ this.props.location.state.siteData.siteId} domain={this.props.location.state.siteData.fqdn} handleCancel={this.handleCancel} editMenuData={this.state.editMenuData} allSerType={this.state.allSerType} siteData={this.props.location.state.siteData} />
                        </Modal>

                        <Modal
                          title={"Add Labels"}
                          visible={this.state.showLabelsModal}
                          width="60%"
                          onCancel={this.handleLableCancel}
                          footer={null}
                          destroyOnClose
                        >
                         <AddCustomLables siteData={this.props.location.state.siteData} handleLableCancel={this.handleLableCancel}/>
                        </Modal>
                  
            </App>
        )
    }
}

const WrappedServiceTypeList = Form.create({ name: "JMenu_form" })(
    ServiceTypeList
  );
export default withApollo(WrappedServiceTypeList)