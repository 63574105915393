import gql from 'graphql-tag'

export default gql`
mutation updateSiteByAdmin(
    $domain: String!
    $fqdn: String!
    $siteId: String!
    $internalName: String!
    $enabled: Boolean!
    $siteType: SITE_TYPE!
    $siteOptions: SITE_OPTIONS
    $integratePaymentOption: [PAYMENT_MODE!]
    $contactEmail: String!
    $contactNumber: String!
    $additionalContactNumbers: [String]
    $no_of_products: Int
    $no_of_photos: Int
    $custName: String
    $companyName: String
    $gstNo: String
    $fromDate: Int
    $tillDate: Int
    $courierOption: COURIER_OPTION
    $homePageTitle:String
    $homePageMetadesc: String
    $backgroundColor: String
    $menuFontColor: String
    $textFontColor: String
    $textFontType: String
    $whatsAppSeller: Boolean
    $whatsAppBuyer: Boolean
    $emailAppBuyer: Boolean
    $feedBackToBuyer: Boolean
    $isDemoSite: Boolean
    $inventory: Boolean
    $frontSite: Boolean
    $availableWhatsApp: Int
    $siteLogo: S3ObjectInput
    $footerAddress: GenericAddressInput
    $festivalBanners: Boolean       
    $isCustomWhatsapp: Boolean       
    $themeDesign: THEME_DESIGN
    $diningSetup: Boolean
    $isWalletEnabled: Boolean
    $diningSettingMenu: String
    $siteMode: SITE_MODE
    $isWebAppEnabled: Boolean
    $paymentModes: [PaymentModeConfigInput!]
    $isDayClosureEnabled: Boolean
    $groupId: String
    $salesPerson: String
    $supportAccManager: String
    $channelPartner: String
    $supportNumber: String
    $stockistName: String
    $isCounterEnabled: Boolean
    $isReturnOrderEnabled: Boolean
    $htmlPrint: Boolean
    $partnerType: String
    $dashboardRangeEnabled: Boolean
    $newOrderAppEnabled: Boolean
    $isManualDayClosure: Boolean
    $isCustOnGroupEnabled: Boolean

  ){
    updateSiteByAdmin(input:{
        domain: $domain,
        fqdn: $fqdn,
        internalName: $internalName,
        enabled: $enabled,
        siteType: $siteType,
        siteOptions:$siteOptions,
        integratePaymentOption: $integratePaymentOption,
        contactEmail: $contactEmail,
        contactNumber: $contactNumber,
        additionalContactNumbers: $additionalContactNumbers
        siteId: $siteId,
        no_of_products: $no_of_products,
        no_of_photos: $no_of_photos,
        custName: $custName,
        companyName: $companyName,
        gstNo: $gstNo,
        fromDate: $fromDate,
        tillDate: $tillDate
        courierOption:$courierOption
        homePageTitle  : $homePageTitle
        homePageMetadesc : $homePageMetadesc
        backgroundColor : $backgroundColor
        menuFontColor: $menuFontColor
        textFontColor : $textFontColor
        textFontType : $textFontType
        whatsAppSeller : $whatsAppSeller
        whatsAppBuyer : $whatsAppBuyer
        emailAppBuyer: $emailAppBuyer
        feedBackToBuyer: $feedBackToBuyer
        isDemoSite: $isDemoSite
        inventory: $inventory
        frontSite : $frontSite
        availableWhatsApp : $availableWhatsApp
        siteLogo:$siteLogo
        footerAddress: $footerAddress
        festivalBanners:$festivalBanners
        isCustomWhatsapp:$isCustomWhatsapp
        themeDesign: $themeDesign
        diningSetup: $diningSetup
        isWalletEnabled: $isWalletEnabled
        diningSettingMenu: $diningSettingMenu
        siteMode: $siteMode
        isWebAppEnabled: $isWebAppEnabled
        paymentModes: $paymentModes
        isDayClosureEnabled: $isDayClosureEnabled
        groupId: $groupId
        salesPerson: $salesPerson
        supportAccManager: $supportAccManager
        channelPartner: $channelPartner
        stockistName: $stockistName
        isCounterEnabled: $isCounterEnabled
        isReturnOrderEnabled: $isReturnOrderEnabled
        htmlPrint: $htmlPrint
        partnerType: $partnerType
        supportNumber: $supportNumber
        dashboardRangeEnabled: $dashboardRangeEnabled
        newOrderAppEnabled: $newOrderAppEnabled
        isManualDayClosure: $isManualDayClosure
        isCustOnGroupEnabled: $isCustOnGroupEnabled


      })
  }
`