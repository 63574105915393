import React, { Component } from 'react';
import { Table, Button,Row,Select, DatePicker   } from 'antd';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import getSitesBySiteType from '../../queries/getSitesBySiteType'
import getSubscriptionPlansBySiteId from '../../queries/getSubscriptionPlansBySiteId'
import createUserQueueInBulkByAdmin from '../../mutation/createUserQueueInBulkByAdmin'
import createCampaignHistorybyAdmin from '../../mutation/createCampaignHistorybyAdmin'
import SuccessComp from './SuccessComp'

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
var PLATFORM = [
    // { value: 'ENQUIRY', label: 'Enquiry' },
    { value: 'BOOKING_RESTAURANTS', label: 'Restaurant' },
    { value: 'BOOKING_SALOON', label: 'Saloon' },
    // { value: 'BOOKING_ENQUIRY', label: 'Enquiry' },
    // { value: 'BOOKING_LAB', label: 'Diagnostic Lab' },
    { value: 'BOOKING_VACCINATION', label: 'Vaccination Program' },
    { value: 'LAUNDRY', label: 'Laundry' },
    { value: 'EVENT', label: 'Event' },
    { value: 'VILLA_RENTAL', label: 'Vill Rental' },
    { value: 'SERVICES', label: 'Services' },
    { value: 'CAR_RENTAL', label: 'Car Rental' },
    { value: 'CONSTRUCTION', label: 'Construction' },
    { value: 'GROCERY', label: 'Grocery' },
    { value: 'GENERAL', label: 'General' },
    { value: 'LAWYER', label: 'Lawyer' },
    { value: 'PET_GROOMERS', label: 'Pet Groomers' },
    { value: 'REPAIR_SERVICES', label: 'Repair Services' },
    { value: 'SPA', label: 'Spa' },
    { value: 'GYM', label: 'Gym' },
    { value: 'MANUFACTURER', label: 'Manufacturer' },
    { value: 'JEWELLERY', label: 'Jewellery' },
    { value: 'MARRIAGE', label: 'Marriage' },
    { value: 'AUTO_SERVICES', label: 'Auto Services' },
    { value: 'DIAGNOSTIC_LAB', label: 'Diagnostic Lab' },
    { value: 'DOCTOR', label: 'Doctor' },
    { value: 'VACATION_RENTALS', label: 'Vacation Rentals' },
    { value: 'GRANITE_BUSINESS', label: 'Granite Business' },
    { value: 'WAREHOUSE', label: 'Warehouse' },
    { value: 'DEPARTMENT', label: 'Department' },
]
class MarketingIndex extends Component {
    constructor(props){
        super(props)
        this.state={
            loading: false,
            data:[],
            allData: [],
            siteType: "O2OCommerce",
            siteOption: undefined,
            sitePlan: undefined,
            allPlan:[],
            nextToken: '',
        }
    }
    componentDidMount(){
        this.getCustList('O2OCommerce',700,'')
        this.getMasterPlan()
    }
    getCustList=(siteType,limit,nextToken)=>{
        this.enterLoading()
            this.props.client.query({
                query: getSitesBySiteType,
                variables: {
                    siteType: siteType,
                    limit: limit,
                    nextToken: nextToken
                },
                fetchPolicy: 'network-only',
            })
                .then(({ data }) => {
                    console.log("getSiteCustomerList ::",data)
                    // this.createDataSource(data.getSitesBySiteType.items)
                    let nextToken =data.getSitesBySiteType.nextToken
                    let data1 = data.getSitesBySiteType.items.concat(this.state.data)
                    this.setState({
                        data:data1
                    })
                    if (nextToken != null && nextToken != undefined && nextToken != '') {
                          this.getCustList(siteType,700,nextToken)
                    }else{
                        this.createDataSource(data1)
                    }
                })
       
    }
    getMasterPlan=()=>{
        this.props.client.query({
            query: getSubscriptionPlansBySiteId,
            variables: {
                siteId:'master',
            },
            fetchPolicy: 'network-only',
        })
            .then(({ data }) => {
                console.log("getSubscriptionPlansBySiteId ::",data)
                if(data && data.getSubscriptionPlansBySiteId){
                    // this.siteOptionPlanFilter(data.getSubscriptionPlansBySiteId,this.state.siteType,this.state.siteOption)
                    this.setState({
                        allPlan: data.getSubscriptionPlansBySiteId
                    })
                }
            })
    }
    createDataSource=(data)=>{
        let arr = []
        data.map((ele,i)=>{
            // if(ele.planDetails.length >1){
            //     console.log("Domain ::",ele.fqdn,i)
            // }
            let obj ={
                fqdn: ele.fqdn,
                custName: ele.custName,
                basicSiteSetting: ele.basicSiteSetting,
                mobile: ele.basicSiteSetting && ele.basicSiteSetting.contactNumber ? ele.basicSiteSetting.contactNumber : null,
                companyName: ele.basicSiteSetting && ele.basicSiteSetting.companyName ? ele.basicSiteSetting.companyName : null,
                siteType: ele.siteType,
                siteOptions: ele.siteOptions,
                createdAt: ele.createdAt,
                userName: ele.userName,
                planDetails: ele.planDetails && ele.planDetails.length >0 ? ele.planDetails.map((val)=> {return val.planName}) : null

            }
            arr.push(obj)
        })
        // console.log("Domain ::",arr)
        this.setState({
            data: arr ? arr: [],
            allData:  arr ? arr: [],
            loading: false
        })
    }
    siteOptionPlanFilter=(allPlan,siteType,platform)=>{
        let sort = allPlan.filter( function(hero){
            return (platform ? (hero.siteType == siteType && hero.platform == platform) : hero.siteType == siteType)
          })
          this.setState({
            planData: sort
          })
    }
    siteDataFilter=(siteData,platform)=>{
       let fData = siteData.filter( function(hero){
            return ( hero.siteOptions == platform)
          })
          this.setState({
            data: fData
          })
    }
    enterLoading=()=>{
        this.setState({
            loading: true
        })
    }
    onSelectChange=(selectedRowKeys,selectedRows)=>{
        console.log("selectedRowKeys ::",selectedRowKeys,selectedRows)
        this.setState({ selectedRowKeys,selectedRows });
    }
    onChangeSelect=(value)=>{
        this.setState({
            siteType: value
        })
    }
    onChangeOption=(value)=>{
          this.siteOptionPlanFilter(this.state.allPlan,this.state.siteType,value)
          this.siteDataFilter(this.state.allData,value)
        this.setState({
            siteOption: value,
        })
    }
    onChangePlan=(value)=>{
        console.log("value ::",value)
        let allData = this.state.allData
        console.log("allData ::",allData)
        let siteOption = this.state.siteOption
        let sort = []
        allData.filter( function(hero){
            if(hero.planDetails && hero.planDetails.length >0 && hero.planDetails.includes(value) && hero.siteOptions == siteOption){
                let obj={...hero}
                sort.push(obj)
            }
          })
          console.log("sort ::",sort)
        this.setState({
            sitePlan: value,
            data: sort
        })
    }
    onValidityDateChange = (date, dateString) => {
        const epochSec1 = moment(dateString[0], 'YYYY-MM-DD').valueOf()
        const sec1 = epochSec1 / 1000
        const epochSec2 = moment(dateString[1], 'YYYY-MM-DD').valueOf()
        const sec2 = epochSec2 / 1000
        var bb = dateString[1];
        let today = moment(bb).add(1439, "m").format("LLL");
        let yg = Math.floor(new Date(today) / 1000);
        let siteData = this.state.allData
        // console.log("yg",yg)
        let fData = siteData.filter( function(hero){
            return ( hero.createdAt >=sec1 && hero.createdAt <= yg)
          })
        //   console.log("fData",fData)
    this.setState({
            data:fData
        })
    }
    generateQueue=async()=>{
        
        let selectedData = this.state.selectedRows
        console.log("selectedTemp",selectedData)
        // return
        let currTemp = this.props.selectedTemp
        let arr = []
        let url = currTemp.image && Object.keys(currTemp.image).length != 0 ? process.env.REACT_APP_S3_URL + '' + currTemp.image.key : null
        if(selectedData.length > 0){
            for(var i=0;i<selectedData.length;i++){
                let content = this.props.content
                let mapObj = {
                    _companyName_: selectedData[i].companyName,
                    _custName_: selectedData[i].custName,
                    _email_ : selectedData[i].email,
                    _fqdn_ : selectedData[i].fqdn,
                    _Balance_Amount_ : selectedData[i].balance,
                    _sitePlan_ : selectedData[i].planDetails ? selectedData[i].planDetails.toString() : '',                   
                    _No_of_Orders_ : selectedData[i].count,
                    _user_name_ : selectedData[i].userName,
                    _Last_Login_ : selectedData[i].lastVisit ? moment.unix(selectedData[i].lastVisit).format("YYYY-MM-DD") : '',
                    
                  }
                  content = content.replace(/\b(?:_companyName_|_custName_|_email_|_fqdn_|_Balance_Amount_|_sitePlan_|_No_of_Orders_|_user_name_|_Last_Login_)\b/gi, matched => mapObj[matched]);
                let obj={
                    "countryCode": "91",
                    "mob": selectedData[i].mobile,
                    "email": selectedData[i].email ? selectedData[i].email : undefined,
                    "language": "eng",
                    "ProductType": "BOOKING_RESTAURANT",
                    "sendingStatus": "PENDING",
                    "subject": "Test",
                    "content": content,
                    "template": this.props.selectedTemp.slugName,
                    "queType": "WHATSAPP",
                    "url": url,
                    "type": url ? "MEDIA" : "TEXT"
                }
                arr.push(obj)
            }
            console.log("arr",arr)
            // return
            let count = arr.length
          let cid = await this.campaignHistory(arr,count)
          console.log("cid",cid)
          let newData =await this.createNewData(arr,cid)
          console.log("newData",newData)
        //   let succ = this.createQueue(newData,count)
        let finalArr = []
        let res = 15
        for (let i = 0; i < newData.length; i++) {
          finalArr.push(newData[i])
          if (i == res) {
            let ress = await this.createQueue(finalArr)
            if (ress == true) {
              res = res + 15
              finalArr = []
            }
          }

        }
        this.createQueue(finalArr)
        this.setState({
            success: true,
            count:count
        })
        // if(count >=1){
        //     message.success(`Your request to send ${count} messages has been queued. It will be delivered in the next 1 hr`)
        // }
       
    }
      }
      campaignHistory=(arr,count)=>{
        console.log("campaignHistory",arr,count)
        return new Promise((resolved,reject)=>{
            this.props.client.mutate({
                mutation: createCampaignHistorybyAdmin,
                variables: {
                    siteId: 'master',
                    language: arr[0].language,
                    ProductType: arr[0].ProductType,
                    sendingStatus: arr[0].sendingStatus,
                    template: arr[0].template,
                    type: arr[0].type,
                    queType: arr[0].queType,
                    total: count,
                }
            }).then(({ data }) => {
                console.log("createCampaignHistory",data)
                if(data.createCampaignHistorybyAdmin){
                    resolved(data.createCampaignHistorybyAdmin.campaignId)
                }
            })
            // resolved(7350099005)
        })
      }
      createNewData=(arr,cid)=>{
        return new Promise((resolve,reject)=>{
            let newArr = []
            arr.map((ele)=>{
                let obj = {...ele,campaignId : cid}
                newArr.push(obj)
            })
            resolve(newArr)
        })
      }
      createQueue=(arr)=>{
        return new Promise((resolved,reject)=>{
            this.props.client.mutate({
                mutation: createUserQueueInBulkByAdmin,
                variables: {
                  input: arr,
                }
            }).then(({ data }) => {
                console.log("Bulk response",data)
                // message.success(`Your request to send ${count+1} messages has been queued. It will be delivered in the next 1 hr`)
                resolved(true)
            })
        })
      }

      allSelect=()=>{
        let allData = this.state.data
        let key = []
        allData.map((ele)=>{
            key.push(ele.fqdn)
        })
        this.setState({ 
            selectedRowKeys:key,
            selectedRows: allData
        });
      }
    render() {
        console.log("data",this.state.data.length)
        const { loading, selectedRowKeys,image,planData } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
          };
        const columns = [
            {
                title: 'Domain',
                dataIndex: 'fqdn',
                key: 'fqdn',
                width: '12%',
            },
            {
                title: 'Comapny Name',
                dataIndex: 'companyName',
                key: 'internalName',
                width: '12%',
                render:(data)=>{
                    let companyName = data ? data: '-'
                    return(
                        <span>{companyName}</span>
                    )
                }
            },
            {
                title: 'Mobile',
                dataIndex: 'basicSiteSetting',
                key: 'Mobile',
                width: '8%',
                render:(data)=>{
                    let contactNumber =data && data.contactNumber ? data.contactNumber: '-'
                    return(
                        <span>{contactNumber}</span>
                    )
                } 
            },
            {
                title: 'Site Type',
                dataIndex: 'siteType',
                key: 'siteType',
                width: '10%',
            },
            {
                title: 'Site Option',
                dataIndex: 'siteOptions',
                key: 'siteOptions',
                width: '12%',
            },
            {
                title: 'Site Plan',
                dataIndex: 'planDetails',
                key: 'planName',
                width: '15%',
                render:(data)=>{
                    let contactNumber = data  ? data.toString(): '-'
                    return(
                        <span>{contactNumber}</span>
                    )
                } 
            },
            {
                title:'Customer Since',
                dataIndex:'createdAt',
                key: 'createdAt',
                width:'12%',
                render:(date)=>{
                    let sDate = date ? moment.unix(date).format("YYYY-MM-DD HH:MM") : ''
                    return(
                        <span>{sDate}</span>
                    )
                }
            }
          
        ]
        return (
            <>
            {this.state.success ?
                <SuccessComp count={this.state.count}/>
            :
            <>
             <div>
             <Select
                    showSearch
                    style={{ width: 180,marginRight: '10px' }}
                    placeholder="Site Type"
                    optionFilterProp="children"
                    onChange={this.onChangeSelect}
                    value={this.state.siteType}
                >
                    <Option value="O2OCommerce">O2O Commerce</Option>
                    <Option value="SIMPLE_B2C">e-Commerce</Option>
                    <Option value="MARKETING">Marketing Site</Option>
                </Select>
             <Select
                    showSearch
                    style={{ width: 180,marginRight: '10px' }}
                    placeholder="Site Option"
                    optionFilterProp="children"
                    onChange={this.onChangeOption}
                    value={this.state.siteOption}
                >
                 {PLATFORM.map((c) => {
                    return (
                        <Option key={c.value} value={c.value}>{c.label}</Option>
                    )})
                    }  
                </Select>
             <Select
                    showSearch
                    style={{ width: 250,marginRight: '10px' }}
                    placeholder="Site Plan"
                    optionFilterProp="children"
                    onChange={this.onChangePlan}
                    value={this.state.sitePlan}
                >
                 {planData && planData.length >0 ?planData.map((c) => {
                    return (
                        <Option key={c.planId} value={c.planName}>{c.planName}</Option>
                    )})
                    : ''}  
                </Select>
                <RangePicker
                  onChange={this.onValidityDateChange} 
                    format={dateFormat}
                    style={{marginRight: '10px'}}
                />
                    {this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0 && 
                    <Button type='primary' onClick={this.generateQueue} disabled={this.state.selectedRows.length >0 ? false : true}>Send</Button>   
                    }
             </div>
             <Row style={{display: 'flex',marginTop: '10px'}}>
                <Button type='primary' onClick={this.allSelect}style={{marginRight: '15px'}} >Select All</Button>
                {this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0 ?
                <span>{`Selected ${this.state.selectedRowKeys.length}`}</span>
                : ''
                }
                </Row>
              <Table
                    dataSource={this.state.data}
                    columns={columns}
                    rowKey="fqdn"
                    loading={this.state.loading}
                    rowSelection={rowSelection}
                    pagination={{ pageSize: 100 }} 
                /> 
                </>
                }
            </>
        );
    }
}

export default withApollo(MarketingIndex);