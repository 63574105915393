import React, { Component } from 'react';
import { Form, Input, Button, Col,Row,Select, message,Radio} from 'antd';
import {  withApollo } from 'react-apollo';
import slugify from 'slugify';
import App from '../../App'
import getSiteConfigServices from '../../queries/getSiteConfigServices'
import updatePlatformService from '../../mutation/updatePlatformService'

const RadioGroup = Radio.Group;
const FormItem = Form.Item;

const PLATFORM = [
    // { value: 'ENQUIRY', label: 'Enquiry' },
    { value: 'BOOKING_RESTAURANTS', label: 'Restaurant' },
    { value: 'BOOKING_SALOON', label: 'Saloon' },
    // { value: 'BOOKING_ENQUIRY', label: 'Enquiry' },
    // { value: 'BOOKING_LAB', label: 'Diagnostic Lab' },
    { value: 'BOOKING_VACCINATION', label: 'Vaccination Program' },
    { value: 'LAUNDRY', label: 'Laundry' },
    { value: 'EVENT', label: 'Event' },
    { value: 'VILLA_RENTAL', label: 'Vill Rental' },
    { value: 'SERVICES', label: 'Services' },
    { value: 'CAR_RENTAL', label: 'Car Rental' },
    { value: 'CONSTRUCTION', label: 'Construction' },
    { value: 'GROCERY', label: 'Grocery' },
    { value: 'LAWYER', label: 'Lawyer' },
    { value: 'PET_GROOMERS', label: 'Pet Groomers' },
    { value: 'REPAIR_SERVICES', label: 'Repair Services' },
    { value: 'SPA', label: 'Spa' },
    { value: 'GYM', label: 'Gym' },
    { value: 'MANUFACTURER', label: 'Manufacturer' },
    { value: 'JEWELLERY', label: 'Jewellery' },
    { value: 'MARRIAGE', label: 'Marriage' },
    { value: 'AUTO_SERVICES', label: 'Auto Services' },
    { value: 'DIAGNOSTIC_LAB', label: 'Diagnostic Lab' },
    { value: 'DOCTOR', label: 'Doctor' },
    { value: 'VACATION_RENTALS', label: 'Vacation Rentals' },
    { value: 'GRANITE_BUSINESS', label: 'Granite Business' },
    { value: 'WAREHOUSE', label: 'Warehouse' },
    { value: 'DEPARTMENT', label: 'Department' },
]
const type = [
    { key: '1', value: 'SIMPLE_B2C', label: 'e-Commerce' },
    { key: '2', value: 'MARKETING', label: 'Marketing Site' },
    // { key: '4', value: 'JEWEL_EXPO_BASIC', label: 'Jewel Expo Basic' },
    // { key: '5', value: 'JEWEL_EXPO_PREMIUM', label: 'Jewel Expo Premium' },
    // { key: '6', value: 'B2B_ECOM', label: 'B2B e-Commerce' },
    { key: '7', value: 'B2C_MARKET_PLACE', label: 'B2C Market Place' },
    // { key: '8', value: 'B2B2C_ECOM', label: 'B2B2C Ecommerce' },
    { key: '9', value: 'O2OCommerce', label: 'O2O Commerce' }
]

class EditPlatform extends Component {
    constructor(props){
        super(props)
        this.state={
            masterService:[],
            status: this.props.location.state.pfData.status,
            serviceType: this.props.location.state.pfData.serviceType
        }
    }
    componentDidMount(){
        this.getServiceCharges()
    }
    getServiceCharges=()=>{
        this.props.client.query({
            query: getSiteConfigServices,
            variables: {
                domain: "recaho"
            },
            fetchPolicy: 'no-cache'
          })
            .then(({ data }) => {
              console.log('dataa', data)
              this.setState({
                masterService: data.getSiteConfigServices,
                loading: false
              })
            })
            .catch(err => {
              console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }
    handleclick=()=>{
        this.props.history.push('/platform-servicelist');
    }
    status = (e) => {
        this.setState({
            status: e.target.value,
        });
    }
    serviceType = (e) => {
        this.setState({
            serviceType: e.target.value,
        });
    }
    handleSubmit = () =>{
        this.props.form.validateFields((err, values) => {
            if(!err){
                // console.log(" values ::",values)
                let slugName = slugify(values.serviceName,{
                    lower: true
                })
                // return
                this.props.client.mutate({
                    mutation: updatePlatformService,
                    variables: {
                        siteId: "master",
                        serviceId: this.props.location.state.pfData.serviceId,
                        serviceName: values.serviceName,
                        slugServiceName: slugName,
                        status: this.state.status,
                        serviceType: this.state.serviceType,
                        masterService: values.masterService,
                        platform: values.platform
                    }
                }).then(({ data }) => {
                //   console.log("Resp Charges ::",data)
                  message.success("Platform Service Updated Successfully");
                  this.handleclick()
                })
            }
        })
    }
    render() {
        // console.log("pro",this.props.location.state.pfData)
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const pfData =  this.props.location.state.pfData

        return (
            <App header={'Edir Platform'}>
                <Form onSubmit={this.handleSubmit} style={{ marginTop: '10px' }}>
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem
                                label="Service Name"
                            >
                                {getFieldDecorator(`serviceName`, {
                                   initialValue: pfData.serviceName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Service Name",
                                    }]
                                })(
                                    <Input placeholder="Service Name" disabled type="text" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        
                        
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem label='Select Site Type' >
                                    {getFieldDecorator('siteType', {
                                         initialValue : pfData.siteType ? pfData.siteType : "",
                                        rules: [{
                                            required: true,
                                            message: "Please Select Site Option",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            disabled
                                            onChange={this.handleSelectChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select">
                                            {type.map((c) => {
                                                return (
                                                    <Select.Option key={c.key} value={c.value} >{c.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                        </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Select Site Option' >
                                    {getFieldDecorator('platform', {
                                         initialValue : pfData.platform ? pfData.platform : "",
                                        rules: [{
                                            required: true,
                                            message: "Please Select Site Option",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please Select Site Option">
                                            {PLATFORM.map((c) => {
                                                return (
                                                    <Select.Option key={c.value} value={c.value} >{c.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                    </Row>
                    <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Master Service' >
                                    {getFieldDecorator('masterService', {
                                         initialValue : pfData.masterService ? pfData.masterService : "",
                                        rules: [{
                                            required: true,
                                            message: "Please select master Service.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please Select Master Service">
                                            {this.state.masterService.map((c) => {
                                                return (
                                                    <Select.Option key={c.id} value={c.serviceName} >{c.serviceName}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem label='Status' >
                                    <RadioGroup onChange={this.status} value={this.state.status}>
                                        <Radio value='ACTIVE' >Active</Radio>
                                        <Radio value="INACTIVE">InActive</Radio>
                                    </RadioGroup>
                        </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem label='Service Type' >
                                    <RadioGroup onChange={this.serviceType} value={this.state.serviceType}>
                                        <Radio value='PLAN' >Plan</Radio>
                                        <Radio value="BASIC">Basic</Radio>
                                    </RadioGroup>
                        </FormItem>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" onClick={(e)=>this.handleSubmit()} loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                </Form>
            </App>
        );
    }
}
const WrappedEditPlatform = Form.create({ name: "platform_form" })(
    EditPlatform
  );

export default withApollo(WrappedEditPlatform);