import React, { Component } from 'react';
import { Form, Input, Button, Col,Row,Select, message,Radio,Checkbox} from 'antd';
import {  withApollo } from 'react-apollo';
import App from '../../App'
import getSiteConfigServices from '../../queries/getSiteConfigServices'
import getPlatformServicesByPlatform from '../../queries/getPlatformServicesByPlatform'
import createSubscriptionPlan from '../../mutation/createSubscriptionPlan'
import { currency } from '../../utils/countryCurrency';

const RadioGroup = Radio.Group;
const FormItem = Form.Item;

var PLATFORM = [
    // { value: 'ENQUIRY', label: 'Enquiry' },
    { value: 'BOOKING_RESTAURANTS', label: 'Restaurant' },
    { value: 'BOOKING_SALOON', label: 'Saloon' },
    // { value: 'BOOKING_ENQUIRY', label: 'Enquiry' },
    // { value: 'BOOKING_LAB', label: 'Diagnostic Lab' },
    { value: 'BOOKING_VACCINATION', label: 'Vaccination Program' },
    { value: 'LAUNDRY', label: 'Laundry' },
    { value: 'EVENT', label: 'Event' },
    { value: 'VILLA_RENTAL', label: 'Vill Rental' },
    { value: 'SERVICES', label: 'Services' },
    { value: 'CAR_RENTAL', label: 'Car Rental' },
    { value: 'CONSTRUCTION', label: 'Construction' },
    { value: 'GROCERY', label: 'Grocery' },
    { value: 'GENERAL', label: 'General' },
    { value: 'LAWYER', label: 'Lawyer' },
    { value: 'PET_GROOMERS', label: 'Pet Groomers' },
    { value: 'REPAIR_SERVICES', label: 'Repair Services' },
    { value: 'SPA', label: 'Spa' },
    { value: 'GYM', label: 'Gym' },
    { value: 'MANUFACTURER', label: 'Manufacturer' },
    { value: 'JEWELLERY', label: 'Jewellery' },
    { value: 'MARRIAGE', label: 'Marriage' },
    { value: 'AUTO_SERVICES', label: 'Auto Services' },
    { value: 'DIAGNOSTIC_LAB', label: 'Diagnostic Lab' },
    { value: 'DOCTOR', label: 'Doctor' },
    { value: 'VACATION_RENTALS', label: 'Vacation Rentals' },
    { value: 'GRANITE_BUSINESS', label: 'Granite Business' },
    { value: 'WAREHOUSE', label: 'Warehouse' },
    { value: 'DEPARTMENT', label: 'Department' },
]


const type = [
    { key: '1', value: 'SIMPLE_B2C', label: 'e-Commerce' },
    { key: '2', value: 'MARKETING', label: 'Marketing Site' },
    // { key: '4', value: 'JEWEL_EXPO_BASIC', label: 'Jewel Expo Basic' },
    // { key: '5', value: 'JEWEL_EXPO_PREMIUM', label: 'Jewel Expo Premium' },
    // { key: '6', value: 'B2B_ECOM', label: 'B2B e-Commerce' },
    { key: '7', value: 'B2C_MARKET_PLACE', label: 'B2C Market Place' },
    // { key: '8', value: 'B2B2C_ECOM', label: 'B2B2C Ecommerce' },
    { key: '9', value: 'O2OCommerce', label: 'O2O Commerce' }
]

class CreateSP extends Component {
    constructor(props){
        super(props)
        this.state={
            masterService:[],
            status: 'ACTIVE',
            services: [],
            basicSer: [],
            master: '',
            siteType: ''
        }
    }
    componentDidMount(){
        this.getServiceCharges()
    }
    getServiceCharges=()=>{
        this.props.client.query({
            query: getSiteConfigServices,
            variables: {
                domain: "recaho"
            },
            fetchPolicy: 'no-cache'
          })
            .then(({ data }) => {
              console.log('dataa', data)
              this.setState({
                masterService: data.getSiteConfigServices,
                loading: false
              })
            })
            .catch(err => {
              console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }
    handleclick=()=>{
        this.props.history.push('/subscription-plan');
    }
    status = (e) => {
        this.setState({
            status: e.target.value,
        });
    }
    masterChange=(value)=>{
        // console.log("value",value)
        if(value){
            this.props.client.query({
                query: getPlatformServicesByPlatform,
                variables: {
                    siteId: "master",
                    platform: this.state.master
                },
                fetchPolicy: 'no-cache'
              })
                .then(({ data }) => {
                  console.log('platformChange', data)
                  let siteType = this.state.siteType
                  let arr  = data.getPlatformServicesByPlatform
                  let sort = arr.filter( function(hero){
                    return hero.masterService == value && hero.serviceType == "PLAN" && hero.siteType == siteType
                  })
                //   console.log('arr ::', arr,this.state.siteType)
                  let Basic = arr.filter( function(hero){
                    return hero.masterService == value && hero.serviceType == "BASIC" && hero.siteType == siteType
                  })
                  console.log('sort', sort)
                //   console.log('Basic', Basic)
                  let newD = []
                  let obj={}
                  Basic.map((val)=>{
                    obj={
                        label: val.serviceName,
                        value: val.serviceId
                    }
                    newD.push(obj)
                  })

                  this.setState({
                    services: sort,
                    basicSer: newD,
                    loading: false
                  })
                })
                .catch(err => {
                  console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
                })
        }
        
    }
    platformChange=(value)=>{
        // console.log("value",value)
        this.setState({
            master:value
        })
        
    }
    handleSubmit = () =>{
        this.props.form.validateFields((err, values) => {
            if(!err){
                console.log(" values ::",values)
                let priceList = []
                let obj = {}
                values.names.map((val)=>{
                    obj={
                        chargingModel : val.name,
                        rate: parseInt(val.rate)*100
                    }
                    priceList.push(obj) 
                })
                // console.log("priceList ::",values.services,values.basic)
                let fserv = values.services
                if(values.basic){
                    fserv =  values.services.concat(values.basic)
                }
                // let fserv= values.services.concat(values.basic)
                // console.log("fserv ::",fserv)

                // return
                this.props.client.mutate({
                    mutation: createSubscriptionPlan,
                    variables: {
                        siteId: "master",
                        planName: values.planName.trim(),
                        status: this.state.status,
                        siteType: values.siteType,
                        masterService: values.masterService,
                        platform: values.platform,
                        curr: values.currency,
                        priceList: priceList,
                        description: values.description,
                        services:fserv
                    }
                }).then(({ data }) => {
                  console.log("Resp Charges ::",data)
                  message.success("Subscription Plan Created Successfully");
                  this.handleclick()
                })
            }
        })
    }
    handleSelectChange = (value) => {
        console.log("value ::",value)
        this.setState({
            siteType: value
        })
        if(value == 'SIMPLE_B2C'){
            PLATFORM = [
                { value: 'COMMERCE', label: 'e-Commerce' },
                { value: 'ENQUIRY', label: 'Enquiry' },
                { value: 'ECOM_CATALOGUE', label: 'Catalogue' },
                // { value: 'ECOM_CATALOGUE', label: 'Catalogue' },
            ]
        }else if(value == 'MARKETING'){
            PLATFORM = [
                { value: 'GENERAL', label: 'General' }
            ]
        }else if(value == 'B2C_MARKET_PLACE'){
            PLATFORM = [
                { value: 'B2B', label: 'B2C Marketplace' },
                { value: 'B2B2C', label: 'B2B Marketplace' },
                { value: 'ENQUIRY', label: 'Enquiry Marketplace' },

            ]
        }else {
            PLATFORM = [
                // { value: 'ENQUIRY', label: 'Enquiry' },
                { value: 'BOOKING_RESTAURANTS', label: 'Restaurant' },
                { value: 'BOOKING_SALOON', label: 'Saloon' },
                // { value: 'BOOKING_ENQUIRY', label: 'Enquiry' },
                // { value: 'BOOKING_LAB', label: 'Diagnostic Lab' },
                { value: 'BOOKING_VACCINATION', label: 'Vaccination Program' },
                { value: 'LAUNDRY', label: 'Laundry' },
                { value: 'EVENT', label: 'Event' },
                { value: 'VILLA_RENTAL', label: 'Vill Rental' },
                { value: 'SERVICES', label: 'Services' },
                { value: 'CAR_RENTAL', label: 'Car Rental' },
                { value: 'CONSTRUCTION', label: 'Construction' },
                { value: 'GROCERY', label: 'Grocery' },
                { value: 'GENERAL', label: 'General' },
                { value: 'LAWYER', label: 'Lawyer' },
                { value: 'PET_GROOMERS', label: 'Pet Groomers' },
                { value: 'REPAIR_SERVICES', label: 'Repair Services' },
                { value: 'SPA', label: 'Spa' },
                { value: 'GYM', label: 'Gym' },
                { value: 'MANUFACTURER', label: 'Manufacturer' },
                { value: 'JEWELLERY', label: 'Jewellery' },
                { value: 'MARRIAGE', label: 'Marriage' },
                { value: 'AUTO_SERVICES', label: 'Auto Services' },
                { value: 'DIAGNOSTIC_LAB', label: 'Diagnostic Lab' },
                { value: 'DOCTOR', label: 'Doctor' },
                { value: 'VACATION_RENTALS', label: 'Vacation Rentals' },
                { value: 'GRANITE_BUSINESS', label: 'Granite Business' },
                { value: 'WAREHOUSE', label: 'Warehouse' },
                { value: 'DEPARTMENT', label: 'Department' },
            ]
        }
        this.setState({
            siteType: value
        })
    }
    onChange=()=>{

    }
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        let charges = [{
            name: "PAY PER USE",
            chargingModel: "PAY_PER_USE",
            rate: null
        },{
            name: "MONTHLY",
            chargingModel: "MONTHLY",
            rate: null
        },{
            name: "YEARLY",
            chargingModel: "YEARLY",
            rate: null
        }]
      getFieldDecorator('keys', { initialValue: charges });
      const keys = getFieldValue('keys');
      const formItems = keys.map((k, index) => (
        <Form.Item
        //   {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
          label={index === 0 ? 'Charges' : ''}
          required={false}
          key={k}
          className="menuFlex"
          // style={{ display: 'flex'}}
        >
          {getFieldDecorator(`names[${index}]name`, {
           initialValue: k.chargingModel,
            rules: [
              {
                required: false,
                message: "Please input passenger's name or delete this field.",
              },
            ],
          })(<Input placeholder="passenger name" disabled style={{ width: '30%', marginRight: 8 }} />)}
            {getFieldDecorator(`names[${index}]rate`, {
            // validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                
                required: false,
                message: "Please input Rate",
              }
            ],
          })(<Input placeholder="Rate"  style={{ width: '30%', marginRight: 8 }} />)}
           {getFieldDecorator(`names[${index}]typeValue`, {
            // validateTrigger: ['onChange', 'onBlur'],
            initialValue: k.typeValue,
            rules: [
              {
                required: false,
                message: "Please input Rate",
              },
            ],
          })(<Input placeholder="Rate" style={{ width: '30%', marginRight: 8, display: "none" }} />)}
        </Form.Item>
      ));

        return (
            <App header={'Create Subscription'}>
                <Form onSubmit={this.handleSubmit} style={{ marginTop: '10px' }}>
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem
                                label="Plan Name"
                            >
                                {getFieldDecorator(`planName`, {
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Plan Name",
                                    }]
                                })(
                                    <Input placeholder="Plan Name" type="text" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                        <FormItem label='Select Site Type' >
                                    {getFieldDecorator('siteType', {
                                        rules: [{
                                            required: true,
                                            message: "Please Select Site Option",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            onChange={this.handleSelectChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select">
                                            {type.map((c) => {
                                                return (
                                                    <Select.Option key={c.key} value={c.value} >{c.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Select Site Option' >
                                    {getFieldDecorator('platform', {
                                        rules: [{
                                            required: true,
                                            message: "Please Select Site Option",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            onChange={this.platformChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please Select Site Option">
                                            {PLATFORM.map((c) => {
                                                return (
                                                    <Select.Option key={c.value} value={c.value} >{c.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        
                    </Row>
                    <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <FormItem label='Master Service' >
                                    {getFieldDecorator('masterService', {
                                        rules: [{
                                            required: true,
                                            message: "Please select master Service.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            onChange={this.masterChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please Select Master Service">
                                            {this.state.masterService.map((c) => {
                                                return (
                                                    <Select.Option key={c.id} value={c.serviceName} >{c.serviceName}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label='Select Services' >
                                    {getFieldDecorator('services', {
                                        rules: [{
                                            required: true,
                                            message: "Please Select Platform",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            mode="multiple"
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please Select Platform">
                                            {this.state.services.map((c) => {
                                                return (
                                                    <Select.Option key={c.serviceId} value={c.serviceId} >{c.serviceName}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                            <FormItem label='Select Basic Service' >
                                    {getFieldDecorator('basic', {
                                        initialValue : this.state.basicSer.length > 0 ? this.state.basicSer.map((val)=> val.value) : '',
                                        rules: [{
                                            required: false,
                                            message: "Please Select Basic Service",
                                        }],
                                    })(
                                        // <Select
                                        //     optionFilterProp="children"
                                        //     // size={size}
                                        //     mode="multiple"
                                        //     filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        //     placeholder="Please Select Platform">
                                        //     {this.state.basicSer.map((c) => {
                                        //         return (
                                        //             <Select.Option key={c.serviceId} value={c.serviceId} >{c.serviceName}</Select.Option>
                                        //         )
                                        //     })}
                                        // </Select>
                                        <Checkbox.Group options={this.state.basicSer}  onChange={this.onChange} />

                                    )}
                                </FormItem>
                    </Col>
                    {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                    <FormItem
                                label="Amount"
                            >
                                {getFieldDecorator(`amount`, {
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: true,
                                        message: "Please enter Amount",
                                    }]
                                })(
                                    <Input placeholder="Amount" type="number" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                    </Col> */}
                    
                    </Row>
                    <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                    <FormItem
                                label="Description"
                            >
                                {getFieldDecorator(`description`, {
                                //    initialValue: this.state.settingData.companyName,
                                    rules: [{
                                        required: false,
                                        message: "Please enter description",
                                    }]
                                })(
                                    <Input placeholder="Description" type="text" style={{ width: '100%' }} />
                                )}
                            </FormItem>
                    </Col>
                   
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                                <FormItem label='Currency' >
                                    {getFieldDecorator('currency', {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: "INR",
                                        rules: [{
                                            required: true,
                                            message: "Please enter currency.",
                                        }],
                                    })(
                                        <Select
                                            optionFilterProp="children"
                                            // size={size}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            placeholder="Please select">
                                            {currency.map((c) => {
                                                return (
                                                    <Select.Option key={c.value} value={c.value} >{c.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        <FormItem label='Status' >
                                    <RadioGroup onChange={this.status} value={this.state.status}>
                                        <Radio value='ACTIVE' >Active</Radio>
                                        <Radio value="INACTIVE">InActive</Radio>
                                    </RadioGroup>
                        </FormItem>
                        </Col>
                           
                    </Row>
                    <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} >
                        {formItems}
                    </Col>
                    </Row>
                    
                    <Row gutter={16}>
                        <Col span={24}>
                            <Button type="primary" onClick={(e)=>this.handleSubmit()} loading={this.state.loading} style={{background:"#389e0d", color:"#fff",marginBottom:"5%",marginRight:"20px" }} >
                                Submit
                            </Button>
                            <Button type="default" style={{background:"#f44336", color:"#fff",marginBottom:"5%" }} onClick={this.handleclick} >Cancel</Button>
                        </Col>    
                    </Row>
                </Form>
            </App>
        );
    }
}
const WrappedCreatePlatform = Form.create({ name: "platform_form" })(
    CreateSP
  );

export default withApollo(WrappedCreatePlatform);