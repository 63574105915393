import gql from 'graphql-tag'
export default gql`
query getServiceBySiteId($siteId: String!){
    getServiceBySiteId(siteId: $siteId){
      serviceName
      slugName
      serviceStatus
      serviceDes
      attachedCat
      attachedAddonCat
      availableSqr
      createdAt
      goodInventory
      badInventory
      metaKeyword
      displayOrder
      pmInventory
      purchaseItemRef
      availableInventory
      costPrice
      tax
      taxType
      tags
      productType
      weightItem
      dietryTag
      isVegan
      meatType
      isPCByQty
      itemLevelName
      size
      isEggless
      message
      messageLoc
      isCelebrationCake
      containsAlcohol
      itemType
      preparationTime
      packingChargeType
      packingCharge
      isDiscountApplicable
      station
      groupId
      addons{
        categoryName
         rule
         isRequired
         customNum
         itemIds
      }
      attachedVariations{
        serviceName
        slugName
        serviceStatus
        serviceDes
        attachedCat
        attachedAddonCat
        availableSqr
        displayOrder
        createdAt
        goodInventory
        badInventory
        metaKeyword
        dietryTag
        isVegan
        meatType
        isPCByQty
        itemLevelName
        size
        isEggless
        message
        messageLoc
        isCelebrationCake
        containsAlcohol
        itemType
        preparationTime
        packingChargeType
        packingCharge
        isDiscountApplicable
        addons{
          categoryName
           rule
           isRequired
           customNum
           itemIds
        }
        tax
        taxType
        tags
        masterProdId
        pmInventory
        purchaseItemRef
        availableInventory
        costPrice
       isInventory
        station
        groupId
        productType
        weightItem
        image {
          bucket
          region
          key
        }
        additionalImages {
          bucket
          region
          key
        }
        serviceRates{
          serviceType
          rate
          listRate
          status
          inStock
        }
      }
      serviceRates{
        serviceType
        rate
        listRate
        status
        inStock
      }
      image {
        bucket
        region
        key
      }
      additionalImages {
        bucket
        region
        key
      }
    }
  }`
//    {
    //    "siteId": "58496ae2-1ed7-4888-a168-1ba69a7a61b9"
    //  }